<template>
  <page v-loading="loading" :borderBottom="true">
    <div slot="headerLeft">
      <span class="spanItem active">{{ $t('deviceList.basicDeviceDetails') }}</span>
      <span
        class="spanItem "
        @click="
          $router.push(
            `/deviceManagement/deviceList/deviceListTask/${$route.params.sn}/${$route.params.model}`
          )
        "
        >{{ $t('deviceList.task') }}</span
      >
      <span
        class="spanItem"
        @click="
          $router.push(
            `/deviceManagement/deviceList/deviceListLog/${$route.params.sn}/${
              $route.params.model
            }/${baseInfo.firmwareVersion || 0}`
          )
        "
        >{{ $t('deviceList.logDownload') }}</span
      >
    </div>
    <div slot="headerRight" class="flex" style="align-items: center;">
      <span @click="refresh" :title="$t('refresh')" class="el-icon-refresh"></span>
      <el-button
        size="small"
        type="primary"
        round
        @click="$router.push('/deviceManagement/deviceList/index')"
        >{{ $t('backList') }}</el-button
      >
    </div>
    <div class="mainWrap">
      <div class="baseWrap">
        <h4>{{ $t('deviceList.basicInfo') }}</h4>
        <div class="content">
          <div class="left">
            <img style="height:280px;width:280px" :src="deviceImg" width="" alt="" />
          </div>

          <div class="right">
            <li class="liItem" v-for="(item, index) in baseInfoList" :key="index">
              <icon-svg :icon-class="item.icon" />
              <span class="label">{{ item.label }}:</span>
              <span class="value">{{ baseInfo[item.key] }}</span>
            </li>
          </div>
        </div>
      </div>

      <div class="cpuWrap">
        <h4>{{ $t('deviceList.CPUStorageInfo') }}</h4>
        <div class="flex">
          <li class="liItem" v-for="(item, index) in cpuInfoList" :key="index">
            <icon-svg :icon-class="item.icon" />
            <div style="margin-left:10px">
              <p class="label">{{ item.label }}</p>
              <p class="value">{{ cpuInfo[item.key] }}</p>
            </div>
          </li>
        </div>
      </div>

      <div class="appWrap">
        <div class="appHeader">
          <h4>
            {{ $t('deviceList.appsInstalled') }}
          </h4>
          <el-pagination
            @current-change="
              currentPage => {
                paramsApp.currentPage = currentPage
              }
            "
            v-if="totalApp > 50"
            :prev-text="$t('previous')"
            :next-text="$t('nextPage')"
            background=""
            layout="prev, next"
            :total="totalApp"
          />
        </div>
        <div class="nodata" v-if="!appInfoList.length">
          {{ $t('not') }}
        </div>
        <div class="flex">
          <el-card class="listItem" v-for="(item, index) in appInfoList" :key="index">
            <i
              class="el-icon-close"
              v-if="!item.packageName.includes('sedsy.initta')"
              @click="delHandler(item)"
            ></i>

            <div style="display:flex;padding: 0 20px;">
              <div class="left-part">
                <icon-svg :icon-class="item.icon" />
                <el-tag v-if="item.grayScale" size="mini" type="info">{{
                  $t('deviceList.gray')
                }}</el-tag>
              </div>

              <div style="margin-left:10px">
                <p class="label">{{ item.label }}</p>
                <p>
                  <span>{{ item.size }}</span>
                </p>
                <p>
                  <span>{{ $t('deviceList.versionNo') }}：{{ item.version }}</span>
                </p>
                <p v-if="item.packageName">
                  <span>{{ $t('deviceList.packageName') }}：{{ item.packageName }}</span>
                </p>
              </div>
            </div>

            <div class="bottom">
              <el-tag
                @click="operateHandler(item, false)"
                v-if="item.powerBoot"
                style="margin-right:10px"
                size="normal"
                type="primary"
                >{{ $t('deviceList.selfStart') }}</el-tag
              >
              <el-tag @click="operateHandler(item, true)" v-if="item.showApp" size="normal">{{
                $t('deviceList.dominateScreen')
              }}</el-tag>
            </div>
          </el-card>
        </div>
      </div>

      <div class="otherWrap">
        <h4>{{ $t('deviceList.peripheralInfo') }}</h4>
        <el-table v-loading="loadingPeripheral" :data="peripheralDevices" border :max-height="400">
          <el-table-column prop="type" :label="$t('deviceList.peripheralConnectionType')" />
          <el-table-column prop="statusType" :label="$t('deviceList.peripheralStatus')" />
          <el-table-column prop="name" :label="$t('deviceList.peripheralName')" />
          <el-table-column prop="info" :label="$t('deviceList.peripheralInfo')" />
          <el-table-column :label="$t('deviceList.lastOnlineTime')">
            <template slot-scope="{ row }">
              {{ $utils.formatTime(row.lastReportTime) }}
            </template>
          </el-table-column>
          <el-table-column width="100" :label="$t('operation')">
            <template slot-scope="{ row }">
              <el-switch
                v-if="row.operationStatus"
                v-model="row.switchStatus"
                @change="statusUpdate(row)"
              />
              <span v-else>-</span>
            </template>
          </el-table-column>
        </el-table>
        <pagination
          slot="pagination"
          :linkChange="false"
          :total="totalPeripheral"
          :page-size.sync="paramsPeripheral.pageSize"
          :page.sync="paramsPeripheral.currentPage"
          @change="getPeripheralDevices"
        />
      </div>
    </div>
  </page>
</template>

<script>
import { position } from '@/lib/position.js'

export default {
  data() {
    return {
      timer: null,
      deviceImg: '',
      totalPeripheral: 0,
      totalApp: 0,
      currentSn: '',
      paramsPeripheral: {
        currentPage: 1,
        pageSize: 20,
        sn: ''
      },
      paramsApp: {
        currentPage: 1,
        pageSize: 50,
        sn: ''
      },
      loading: true,
      loadingPeripheral: false,
      baseInfo: {
        spName: '',
        onlineStatus: '',
        modelName: '',
        sn: '',
        groupName: '',
        firmwareVersion: '',
        externalIP: '',
        currentIpAddress: '',
        shutdownTime: '',
        innerIP: '',
        startupTime: '',
        address: '',
        cloudPlatformVersion: '',
        store: ''
      },
      cpuInfo: {
        cpu: '',
        cpuUsage: '',
        cpuCores: '',
        memory: '',
        storage: '',
        cpuTemperature: ''
      },
      peripheralDevices: [],
      baseInfoList: [
        {
          icon: 'iconfuwushangxinxi',
          label: this.$t('deviceList.customer'),
          key: 'spName'
        },
        {
          icon: 'icondevicelogs',
          label: this.$t('deviceList.deviceStatus'),
          key: 'onlineStatus'
        },
        {
          icon: 'iconshebeixinghao',
          label: this.$t('deviceList.deviceType'),
          key: 'modelName'
        },
        {
          icon: 'icondevicelogs',
          label: 'SN',
          key: 'sn'
        },
        {
          icon: 'iconshebeifenzuguanli',
          label: this.$t('deviceList.deviceGroup'),
          key: 'groupName'
        },
        {
          icon: 'iconbanbenhao',
          label: this.$t('deviceList.firmwareVersion'),
          key: 'firmwareVersion'
        },
        {
          icon: 'iconip',
          label: this.$t('deviceList.extranetIP'),
          key: 'externalIP'
        },
        {
          icon: 'icondizhi',
          label: this.$t('deviceList.IPResolution'),
          key: 'currentIpAddress'
        },

        {
          icon: 'iconshijian',
          label: this.$t('deviceList.shutdownTime'),
          key: 'shutdownTime'
        },
        {
          icon: 'iconip',
          label: this.$t('deviceList.intranetIP'),
          key: 'innerIP'
        },
        {
          icon: 'iconshijian',
          label: this.$t('deviceList.startupTime'),
          key: 'startupTime'
        },
        {
          icon: 'icondizhi',
          label: this.$t('deviceList.detailedAddress'),
          key: 'address'
        },
        {
          icon: 'iconbanbenhao',
          label: this.$t('deviceList.cloudPlatformVersion'),
          key: 'cloudPlatformVersion'
        },
        {
          icon: 'icondizhi',
          label: this.$t('deviceList.store'),
          key: 'store'
        },
        {
          icon: 'iconbanbenhao',
          label: this.$t('deviceList.AllowLocalInstall'),
          key: 'closeAppStatus'
        }
      ],
      cpuInfoList: [
        // {
        //   icon: 'iconcpu',
        //   label: 'CPU型号',
        //   key: 'cpu'
        // },
        {
          icon: 'iconcpu',
          label: this.$t('deviceList.CPUUtilization'),
          key: 'cpuUsage'
        },
        {
          icon: 'iconcpu',
          label: this.$t('deviceList.kernelNumber'),
          key: 'cpuCores'
        },
        {
          icon: 'iconcpu',
          label: this.$t('deviceList.memory'),
          key: 'memory'
        },
        {
          icon: 'iconcpu',
          label: this.$t('deviceList.disk'),
          key: 'storage'
        },
        {
          icon: 'iconcpu',
          label: this.$t('deviceList.cpuTemperature'),
          key: 'cpuTemperature'
        }
      ],
      appInfoList: [],
      serviceList: []
    }
  },
  async created() {
    const sn = this.$route.params.sn
    const model = this.$route.params.model
    this.currentSn = sn
    this.paramsApp.sn = sn
    this.paramsPeripheral.sn = sn

    this.getPeripheralDevices = this.$utils.debounce(this.getPeripheralDevices, 100)
    this.getApp = this.$utils.debounce(this.getApp, 100)

    const row = JSON.parse(sessionStorage.getItem('currentRow'))

    this.baseInfo.onlineStatus = row.onlineStatus
      ? this.$t('deviceList.offLine')
      : this.$t('deviceList.onLine')
    this.baseInfo.groupName = row.groupName
    this.baseInfo.modelName = row.modelName
    this.baseInfo.spName = row.spName
    this.baseInfo.store = row.store
    this.baseInfo.sn = row.sn
    this.baseInfo.firmwareVersion = row.firmwareVersion || ''
    this.baseInfo.shutdownTime = row.shutdownTime ? this.$utils.formatTime(row.shutdownTime) : ''
    this.baseInfo.startupTime = row.startupTime ? this.$utils.formatTime(row.startupTime) : ''

    //获取设备型号列表
    this.getModel(model)

    //获取详细信息
    this.getData(sn)

    //获取外设列表
    this.getPeripheralDevices()

    //获取app列表
    this.getApp()
  },

  methods: {
    delHandler(item) {
      clearInterval(this.timer)
      this.timer = null
      let wantTouninstall = this.$t('deviceList.wantTouninstall')
      this.$confirm(`${wantTouninstall} ${item.label}?`, this.$t('tips'), {
        confirmButtonText: this.$t('confirm'),
        cancelButtonText: this.$t('cancel')
      })
        .then(async () => {
          this.loading = true

          try {
            await this.$api.deviceManagement.uninstallApp({
              publishObjectIds: this.currentSn,
              appId: item.id
            })

            this.$message.success(this.$t('deviceList.uninstallSuccee'))
            this.getApp()
          } catch (e) {
            console.log(e)
          } finally {
            this.loading = false
          }
        })
        .catch(() => {})
    },
    operateHandler(item, showApp) {
      clearInterval(this.timer)
      this.timer = null

      const tips = showApp
        ? this.$t('deviceList.dominateScreenSetting')
        : this.$t('deviceList.selfStartSetting')

      let isCancel = this.$t('isCancel')
      this.$confirm(`${isCancel} ${item.label} ${tips}?`, this.$t('tips'), {
        confirmButtonText: this.$t('confirm'),
        cancelButtonText: this.$t('cancel')
      })
        .then(async () => {
          this.loading = true

          try {
            const params = { sn: this.currentSn }

            showApp
              ? await this.$api.deviceManagement.cancelShow(params)
              : await this.$api.deviceManagement.cancelPowerBoot({
                  sn: this.currentSn,
                  packageName: item.packageName
                })

            this.$message.success(this.$t('cancelSuccess'))
            this.getApp()
          } catch (e) {
            console.log(e)
          } finally {
            this.loading = false
          }
        })
        .catch(() => {})
    },
    async statusUpdate(row) {
      try {
        await this.$api.deviceManagement.peripheralOperation({
          identification: row.identification,
          sn: row.sn,
          operationStatus: row.switchStatus ? 1 : 2
        })
      } catch (e) {
        row.switchStatus = !row.switchStatus
      }
    },
    async refresh(val) {
      this.loading = true
      if (this.timer) {
        clearInterval(this.timer)
        this.timer = null
      }

      try {
        await this.$api.deviceManagement.deviceSendCommand({
          sn: this.currentSn,
          command: 'device:report'
        })
        this.deviceRefresh()
        this.refreshRender()
        this.timer = setInterval(() => {
          this.refreshRender()
        }, 2000)
      } catch (e) {
        console.log(e)
        this.loading = false
      }
    },
    async deviceRefresh() {
      try {
        await this.$api.deviceManagement.deviceRefresh({ sn: this.currentSn })
      } catch (e) {
        console.log(e)
      }
    },
    async refreshRender() {
      // this.loading = true
      try {
        //获取详细信息
        this.getData(this.currentSn)

        //获取外设列表
        this.getPeripheralDevices()

        //获取app列表
        this.getApp()
      } catch (e) {
        console.log(e)
        clearInterval(this.timer)
        this.timer = null
      }
    },
    async getModel(model) {
      const res = await this.$api.deviceManagement.modelList({ pageSize: -1 })
      const target = ((res.result && res.result.list) || []).find(_ => +_.id === +model)

      if (target) {
        this.deviceImg = target.imagePath
      }
    },

    province(provinceId) {
      return position.find(_ => _.pid === provinceId).name
    },

    statusTypeFilter(type) {
      let status = ''
      switch (type) {
        case 0:
          // status = '打印机正常'
          status = this.$t('deviceList.normal')
          break
        default:
          status = this.$t('deviceList.offLine')
      }
      return status
    },

    async getPeripheralDevices(sn) {
      // this.loadingPeripheral = true

      try {
        const res = await this.$api.deviceManagement.peripheralList(this.paramsPeripheral)
        this.totalPeripheral = res.result.total
        this.peripheralDevices = ((res.result && res.result.list) || []).map(item => {
          item.statusType = this.statusTypeFilter(item.status)
          item.switchStatus = item.operationStatus === 1 ? true : false

          return item
        })
      } catch (e) {
        console.log(e)
      } finally {
        this.loadingPeripheral = false
      }
    },

    async getApp(sn) {
      const res = await this.$api.deviceManagement.appList(this.paramsApp)
      this.totalApp = res.result.total

      this.appInfoList = ((res.result && res.result.list) || []).map(item => {
        return {
          id: item.appId,
          icon: 'iconapp',
          label: item.appName,
          packageName: item.packageName,
          version: item.version ? 'v' + item.version : '',
          size: item.appSize,
          grayScale: item.grayScale,
          showApp: item.showApp,
          powerBoot: item.powerBoot
        }
      })
    },

    async getData(sn) {
      try {
        const res = await this.$api.deviceManagement.deviceGet({ sn: sn })

        const data = res.result

        const currentIpAddress =
          (data.countryId || '') +
          (data.provinceId || '') +
          (data.cityId || '') +
          (data.areaId || '')

        if (data.statusRecords && data.statusRecords.length) {
          const targetRecord = data.statusRecords[0]
          const externalIP = targetRecord.externalIP
          const innerIP = targetRecord.innerIP

          this.baseInfo.externalIP = externalIP
          this.baseInfo.innerIP = innerIP
          this.baseInfo.cloudPlatformVersion = data.cloudPlatformVersion
            ? 'v' + data.cloudPlatformVersion
            : ''
          this.cpuInfo.cpu = targetRecord.cpu || ''
          this.cpuInfo.cpuUsage = targetRecord.cpuUsage || ''
          this.cpuInfo.cpuCores = targetRecord.cpuCores || ''
          this.cpuInfo.cpuTemperature = targetRecord.cpuTemperature || ''
          this.cpuInfo.memory =
            targetRecord.memoryUsed +
            this.$t('deviceList.available') +
            '/' +
            targetRecord.totalMemory +
            this.$t('deviceList.z')
          this.cpuInfo.storage =
            targetRecord.storageUsed +
            this.$t('deviceList.available') +
            '/' +
            targetRecord.totalStorage +
            this.$t('deviceList.z')
          this.baseInfo.currentIpAddress = currentIpAddress
        }

        this.baseInfo.address = data.address || ''
        this.baseInfo.closeAppStatus = data.closeAppStatus ? this.$t('no') : this.$t('yes')
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    }
  },
  watch: {
    paramsPeripheral: {
      handler(val) {
        this.getPeripheralDevices(val)
      },
      deep: true
    },
    paramsApp: {
      handler(val) {
        this.getApp(val)
      },
      deep: true
    }
  },
  beforeDestroy() {
    clearInterval(this.timer)
    this.timer = null
  }
}
</script>

<style scoped lang="scss">
.el-button {
  padding: 6px 15px;
  // margin-bottom: 5px;
}

.el-icon-refresh {
  font-size: 20px;
  color: #666;
  font-weight: bold;
  cursor: pointer;
}

.mainWrap {
  font-size: 14px;
  display: flex;
  flex-direction: column;
  overflow: auto;
  ::v-deep .el-pagination.is-background .btn-next,
  ::v-deep .el-pagination.is-background .btn-prev {
    padding: 0 10px !important;
  }
  .baseWrap,
  .cpuWrap,
  .appWrap {
    margin-bottom: 20px;
    .appHeader {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 60px;
      margin-right: 70px;
    }
  }

  .nodata {
    color: #666;
    font-size: 12px;
    position: relative;
    top: -15px;
  }

  h4 {
    margin-bottom: 15px;
    color: #515151;
  }

  .flex {
    flex-wrap: wrap;
    span {
      word-break: break-word;
    }
  }

  .svg-icon {
    width: 30px;
    height: 30px;
    color: #39c8a8;
  }

  .baseWrap {
    .content {
      display: flex;
      div {
        display: flex;
        flex-wrap: wrap;
      }

      .right {
        margin-left: 30px;
      }
    }
    .liItem {
      display: flex;
      align-items: center;
      width: 30%;

      @media screen and (max-width: 1700px) {
        width: 44%;
      }

      margin-bottom: 30px;
      flex-wrap: wrap;
      word-break: break-word;

      .label {
        margin: 0 6px 0 10px;
      }
    }
  }
  .cpuWrap {
    .svg-icon {
      width: 50px;
      height: 50px;
    }
    .liItem {
      display: flex;
      align-items: center;
      width: 33%;
      margin-bottom: 20px;
      p {
        word-break: break-word;
      }
      & > div {
        .label {
          margin-bottom: 2px;
          word-break: break-word;
        }
      }
    }
  }
  .appWrap {
    & > div {
      max-height: 300px;
      overflow-y: auto;
      margin-right: 20px;
    }

    .el-card {
      position: relative;
      width: 23%;

      @media (min-width: 1920px) {
        width: 24%;
      }

      margin-right: 10px;
      margin-bottom: 10px;

      .el-icon-close {
        color: #f56c6c;
        position: absolute;
        right: 0;
        top: 0;
        font-size: 25px;
        cursor: pointer;
      }

      .bottom {
        border-top: 1px solid #ccc;
        padding: 10px 0;
        margin: 0;
        width: 100%;
        display: flex;
        justify-content: center;
        font-size: 25px;
        margin-top: 8px;

        .el-tag {
          cursor: pointer;
        }
      }

      ::v-deep .el-card__body {
        display: flex;
        flex-direction: column;
        padding: 20px 0 0;

        .left-part {
          display: flex;
          flex-direction: column;
          align-items: center;

          .el-tag {
            width: 50px;
            text-align: center;
            margin-top: 6px;
            font-weight: bold;
          }
        }

        .svg-icon {
          width: 40px;
          height: 40px;
          flex-shrink: 0;
        }
      }
      .label {
        word-break: break-word;
      }
    }
  }
  .otherWrap {
    padding-right: 20px;
  }
}
</style>
