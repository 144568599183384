<template>
  <div class="appView">
    <div class="appHeader">
      <el-menu :default-active="activeIndex" class="el-menu-demo" mode="horizontal">
        <el-menu-item
          @click="renderData(item.id)"
          v-for="item in menus"
          :index="item.id"
          :key="item.id"
          >{{ item.name }}</el-menu-item
        >
      </el-menu>
      <el-radio-group v-model="status">
        <el-radio v-for="item in statusOptions" :key="item.label" :label="item.value">{{
          item.label
        }}</el-radio>
      </el-radio-group>
      <div class="download-wrap">
        <el-link @click="download" type="primary">{{
          $t('operateList.downloadDeviceList1')
        }}</el-link>
      </div>
    </div>

    <div class="table-wrap">
      <el-table :data="tableData" border v-loading="loading" height="100%">
        <el-table-column
          min-width="150"
          show-overflow-tooltip
          prop="sn"
          :label="$t('deviceList.sn')"
        />
        <el-table-column
          show-overflow-tooltip
          prop="modelName"
          :label="$t('deviceList.deviceType')"
        />
        <el-table-column
          show-overflow-tooltip
          min-width="200"
          prop="groupName"
          :label="$t('deviceList.deviceGroup')"
        />
        <!-- <el-table-column label="操作" width="130">
          <template slot-scope="{ row }">
            <div class="operateRow">
              <el-button type="primary" size="small" @click="toDetail(row)">详情</el-button>
            </div>
          </template>
        </el-table-column> -->
      </el-table>
    </div>
    <pagination
      slot="pagination"
      :total="total"
      :page-size.sync="params.pageSize"
      :page.sync="params.currentPage"
      @change="getData"
    />
    <detail-modal
      :current-item="currentItem"
      :faterItem="faterItem"
      :visible.sync="detailVisible"
      v-if="detailVisible"
    />
  </div>
</template>

<script>
import watchParamsGetData from '@/mixins/watchParamsGetData'
import DetailModal from './DetailModal'

export default {
  mixins: [watchParamsGetData],
  props: ['operateType', 'faterItem'],
  data() {
    return {
      currentId: '',
      status: 1,
      activeIndex: '0',
      detailVisible: false,
      firstIn: true,
      currentItem: null,
      modelList: [],
      tableData: []
    }
  },
  components: {
    DetailModal
  },
  created() {
    this.activeIndex = (this.faterItem.appInfos && this.faterItem.appInfos[0].appId + '') || '0'
  },
  computed: {
    statusOptions() {
      const statusOptions1 = [
        {
          label: this.$t('operateList.pushSuccee'),
          value: 1
        },
        {
          label: this.$t('operateList.downloaded'),
          value: 2
        },
        {
          label: this.$t('operateList.alreadyInstalled'),
          value: 3
        }
      ]

      const statusOptions2 = [
        {
          label: this.$t('operateList.pushSuccee'),
          value: 1
        },
        {
          label: this.$t('operateList.noApp'),
          value: 6
        },
        {
          label: this.$t('operateList.uninstallComplete'),
          value: 5
        }
      ]

      return this.operateType === 2 ? statusOptions2 : statusOptions1
    },
    menus() {
      let arr = []

      if (this.faterItem && this.faterItem.appInfos) {
        arr = this.faterItem.appInfos.map(item => ({
          id: item.appId + '',
          name: item.appName
        }))
      }
      return arr
    }
  },
  watch: {
    status(val) {
      this.getData()
    }
  },
  methods: {
    renderData(id) {
      this.currentId = id
      this.getData()
    },
    async download() {
      const loading = this.$loading()
      try {
        const params = { ...this.params }
        params.pageSize = -1
        const res = await this.$api.appManagement.downloadListByStatus(params)

        this.$utils.downFile(res)
      } catch (e) {
        console.log(e)
      } finally {
        setTimeout(() => {
          loading.close()
        }, 1000)
      }
    },
    async getData() {
      this.loading = true

      try {
        const params = this.params

        this.params.status = this.status

        this.params.appId = this.currentId || (this.menus && this.menus[0]?.id) || ''

        this.params.publishId = this.faterItem.publishId

        const res = await this.$api.appManagement.listFromApp(params)

        if (res && res.result) {
          this.tableData = res.result.list
          this.total = res.result.total
        } else {
          this.tableData = []
          this.total = 0
        }
      } catch (err) {
        console.log(err)
      } finally {
        this.loading = false
      }
    },

    toDetail(row) {
      this.detailVisible = true
      this.currentItem = row
    }
  }
}
</script>

<style scoped lang="scss">
.appView {
  .appHeader {
    .el-menu {
      display: flex;
      justify-content: center;
      margin: 0 10px 10px;
    }

    .el-radio-group {
      margin: 15px 0;
    }
    .download-wrap {
      float: right;
      margin-top: 10px;
    }
  }

  .table-wrap {
    min-height: 230px;
    .row {
      display: flex;

      flex-wrap: wrap;
      img {
        margin-right: 10px;
      }
      & > div {
        border: 1px solid #ccc;
        margin: 5px;
        padding: 5px;
        display: flex;
        align-items: center;
        flex-direction: column;
        .name {
          font-size: 15px;
          font-weight: bold;
          margin: 10px 0;
        }

        & + div {
          margin-left: 10px;
        }
      }
    }
  }
}
</style>
