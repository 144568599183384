import { render, staticRenderFns } from "./AddAppModal.vue?vue&type=template&id=55bd3947&scoped=true"
import script from "./AddAppModal.vue?vue&type=script&lang=js"
export * from "./AddAppModal.vue?vue&type=script&lang=js"
import style0 from "./AddAppModal.vue?vue&type=style&index=0&id=55bd3947&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "55bd3947",
  null
  
)

export default component.exports