<template>
  <page>
    <div slot="headerLeft">
      <el-input
        :placeholder="$t('appList.appName')"
        v-model.trim="params.appName"
        @keyup.enter.native="replaceData"
      />
      <el-button type="primary" plain @click="replaceData">{{ $t('query') }}</el-button>
    </div>
    <div slot="headerRight">
      <c-button
        v-if="btnShow && btnShow.add"
        type="primary"
        round
        icon="iconxinzeng"
        @click="
          currentItem = null
          visible = true
        "
        >{{ $t('appList.uploadApp') }}</c-button
      >
    </div>

    <div class="mainCot" v-loading="loading" v-scroll="loadmore">
      <template v-if="appList.length">
        <el-card class="listItem" v-for="item in appList" :key="item.appId">
          <div class="row viewer" v-viewer>
            <img :src="item.iconPathUrl" />
            <div style="margin-left:20px;flex:1">
              <h3 class="label">{{ item.appName }}</h3>
              <p>{{ item.appSize }}</p>
              <p :title="item.versionName" class="label">
                {{ $t('deviceList.versionNo') }}：{{ item.versionName }}
              </p>
            </div>
          </div>
          <div class="el-card-bottom">
            <el-button
              v-if="btnShow && btnShow.detail"
              size="small"
              type="primary"
              @click="detailHandler(item)"
              >{{ $t('details') }}</el-button
            >
            <el-button
              v-if="btnShow && btnShow.edit"
              size="small"
              type="primary"
              @click="updateHandler(item)"
              >{{ $t('update') }}</el-button
            >
            <el-button
              v-if="btnShow && btnShow.delete"
              size="small"
              type="danger"
              @click="delHandler(item)"
              >{{ $t('delete') }}</el-button
            >
          </div>
        </el-card>
      </template>
      <p class="no-data" v-else>{{ $t('noData') }}</p>
    </div>

    <add-app-modal
      @getData="replaceData"
      :visible.sync="visible"
      :current-item="currentItem"
      v-if="visible"
    />
    <app-detail-modal :app-info="currentItem" :visible.sync="visible2" v-if="visible2" />
  </page>
</template>

<script>
import watchParamsGetData from '@/mixins/watchParamsGetData'
import AddAppModal from './components/AddAppModal'
import AppDetailModal from './components/AppDetailModal'

export default {
  mixins: [watchParamsGetData],
  components: {
    AddAppModal,
    AppDetailModal
  },
  data() {
    return {
      visible: false,
      visible2: false,
      appList: [],
      currentItem: null
    }
  },
  methods: {
    loadmore() {
      if (this.total < this.params.pageSize) {
        return
      }

      ++this.params.currentPage
      this.getData()
    },
    detailHandler(item) {
      this.visible2 = true
      this.currentItem = item
    },
    async delHandler(row) {
      try {
        await this.$confirm(this.$t('appList.deleteApp'), this.$t('tips'), {
          confirmButtonText: this.$t('confirm'),
          cancelButtonText: this.$t('cancel')
        })
        this.loading = true

        await this.$api.appManagement.appDelete({ appId: row.appId })
        this.$message.success(this.$t('deleteSuccess'))
        this.getData(false)
      } catch (err) {
      } finally {
        this.loading = false
      }
    },
    updateHandler(item) {
      this.visible = true
      this.currentItem = item
    },
    async getData() {
      try {
        if (this.noMore && this.params.currentPage !== 1) return

        this.loading = true
        const res = await this.$api.appManagement.appList(this.params)

        if (res.result) {
          const list = (res.result.list || []).map(item => {
            item.appSize = this.$utils.bytesToSize(item.appSize)
            return item
          })
          this.appList = this.params.currentPage === 1 ? list : this.appList.concat(list)
          if (!list.length && this.params.currentPage !== 1) this.noMore = true
          this.total = res.result.total
        } else {
          this.tableData = []
          this.total = 0
        }
      } catch (err) {
        console.log(err)
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style scoped lang="scss">
.mainCot {
  position: relative;
  min-height: 400px;
  overflow: auto;
  height: 0;
  flex: 1;
  .listItem {
    width: 30%;
    display: inline-block;
    margin-right: 28px;
    height: 160px;
    margin-bottom: 10px;
    min-width: 380px;
    ::v-deep .el-card__body {
      display: flex;
      flex-direction: column;

      div:first-child {
        display: flex;
        margin-bottom: 20px;
        h3 {
          margin-bottom: 4px;
          font-size: 16px;
        }
      }

      img {
        width: 50px;
        height: 50px;
      }

      .label {
        max-width: 95%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }
  .el-card-bottom {
    text-align: right;
  }
}
</style>
