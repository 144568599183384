<template>
  <el-dialog
    v-elDragDialog
    width="700px"
    :title="$t('details')"
    :close-on-click-modal="false"
    :visible.sync="show"
    class="deviceDetail"
  >
    <el-table :data="tableData" border v-loading="loading" :height="400">
      <el-table-column :label="$t('appList.appIcon')">
        <div slot-scope="{ row }" class="row viewer" v-viewer>
          <img :src="row.iconPathUrl" />
        </div>
      </el-table-column>

      <el-table-column show-overflow-tooltip prop="appName" :label="$t('appList.appName')" />
      <el-table-column show-overflow-tooltip prop="status" :label="$t('activeDevice.state')" />
    </el-table>

    <!-- <div class="pagination-wrap">
      <el-pagination
        :page-sizes="[10, 20, 30, 40, 50, 100, 200]"
        :total="total"
        :page-size="params.pageSize"
        :current-page="params.currentPage"
        @size-change="
          pageSize => {
            params.pageSize = pageSize
            getData()
          }
        "
        @current-change="
          currentPage => {
            params.currentPage = currentPage
            getData()
          }
        "
        layout="total, sizes, prev, pager, next, jumper"
      />
    </div> -->
  </el-dialog>
</template>

<script>
import dialogCommonParams from '@/mixins/dialogCommonParams'
export default {
  mixins: [dialogCommonParams],
  components: {},
  data() {
    return {
      tableData: [],
      statusList: {
        0: this.$t('operateList.notPushed'),
        1: this.$t('operateList.pushed'),
        2: this.$t('operateList.downloaded'),
        3: this.$t('operateList.alreadyInstalled'),
        4: this.$t('operateList.run'),
        5: this.$t('operateList.uninstalled'),
        6: this.$t('operateList.noApp'),
        9: this.$t('operateList.pushFailed')
      },
      total: 0,
      params: {
        // pageSize: 50,
        // currentPage: 1
      },
      loading: false
    }
  },
  props: ['faterItem'],
  created() {
    this.getData()
  },
  methods: {
    async getData() {
      try {
        this.loading = true
        const params = this.params
        params.sn = this.currentItem.sn
        params.publishId = this.faterItem.publishId

        const res = await this.$api.appManagement.checkAppList(params)

        if (res.result) {
          this.tableData = res.result.map(item => {
            item.status = this.statusList[item.status]
            return item
          })
          // this.total = res.result.total
        }
      } catch (err) {
        console.log(err)
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.deviceDetail {
  img {
    width: 50px;
    height: 50px;
  }
  .pagination-wrap {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    height: 60px;
    box-sizing: border-box;
  }
}
</style>
