<template>
  <page>
    <div slot="headerLeft">
      <el-input
        v-model.trim="params.appName"
        :placeholder="$t('appList.appName')"
        @keyup.enter.native="getData"
      />
      <el-button type="primary" plain @click="replaceData()">{{ $t('query') }}</el-button>
    </div>
    <div class="table-wrap">
      <el-table
        @expand-change="expandChange"
        row-key="id"
        :expand-row-keys="expands"
        :data="tableData"
        border
        v-loading="loading"
        height="100%"
      >
        <el-table-column :label="$t('appList.appIcon')">
          <div slot-scope="{ row }" class="row viewer" v-viewer>
            <img :src="row.iconPathUrl" />
          </div>
        </el-table-column>

        <el-table-column show-overflow-tooltip prop="appName" :label="$t('appList.appName')" />
        <el-table-column
          show-overflow-tooltip
          prop="versionName"
          :label="$t('appList.appVersion')"
        />
        <el-table-column show-overflow-tooltip prop="appSize" :label="$t('appList.appSize')" />
        <el-table-column show-overflow-tooltip prop="createTime" :label="$t('appList.launchTime')">
          <template slot-scope="{ row }">
            {{ $utils.formatTime(row.createTime) }}
          </template>
        </el-table-column>

        <el-table-column width="120" :label="$t('operation')">
          <template slot-scope="{ row, $index }">
            <span style="cursor:pointer;" @click="toggleRowExpansion(row, $index)">
              {{ $t('appList.historicalVersion') }}
            </span>
          </template>
        </el-table-column>

        <el-table-column type="expand">
          <template slot-scope="{ row }">
            <div class="expandWrap">
              <el-table v-loading="row.loading" :data="row.expandData" border max-height="200px">
                <el-table-column
                  show-overflow-tooltip
                  prop="versionName"
                  :label="$t('appList.appVersion')"
                />
                <el-table-column
                  show-overflow-tooltip
                  prop="appSize"
                  :label="$t('appList.appSize')"
                />
                <el-table-column
                  show-overflow-tooltip
                  prop="status"
                  :label="$t('appList.launchTime')"
                >
                  <template slot-scope="{ row }">
                    {{ $utils.formatTime(row.createTime) }}
                  </template>
                </el-table-column>

                <el-table-column :label="$t('operation')">
                  <template slot-scope="scope">
                    <div class="operateRow">
                      <el-button
                        v-if="btnShow && btnShow.detail"
                        type="text"
                        @click="detailHandler(scope.row)"
                        >{{ $t('details') }}</el-button
                      >
                      <el-button
                        v-if="btnShow && btnShow.downLoad"
                        type="text"
                        @click="downLoad(scope.row)"
                        >{{ $t('download') }}</el-button
                      >
                      <el-button
                        v-if="btnShow && btnShow.delete"
                        type="text"
                        @click="delHandler(scope.row, row.expandData, scope.$index)"
                        >{{ $t('delete') }}</el-button
                      >
                    </div>
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <pagination
      slot="pagination"
      :total="total"
      :page-size.sync="params.pageSize"
      :page.sync="params.currentPage"
      @change="getData"
    />
    <app-detail-modal :app-info="currentItem" :visible.sync="detailVisible" v-if="detailVisible" />
  </page>
</template>

<script>
import watchParamsGetData from '@/mixins/watchParamsGetData'
import AppDetailModal from '../appList/components/AppDetailModal'

export default {
  mixins: [watchParamsGetData],
  components: {
    AppDetailModal
  },
  data() {
    return {
      visible: false,
      currentItem: {},
      tableData: [],
      expands: [],
      detailVisible: false
    }
  },
  created() {},
  methods: {
    async getData(loading = true) {
      try {
        this.loading = true
        const res = await this.$api.appManagement.appList(this.params)

        if (res.result) {
          this.tableData = (res.result.list || []).map(item => {
            item.loading = false
            item.appSize = this.$utils.bytesToSize(item.appSize)
            item.expandData = []
            return item
          })
          this.total = res.result.total
        } else {
          this.tableData = []
          this.total = 0
        }
      } catch (err) {
        console.log(err)
      } finally {
        this.loading = false
      }
    },

    async expandChange(row, array) {
      if (array.length) {
        this.expands = [row.id]
        this.renderExpandChildData(row)
      }
      row.showChildList = array.length
    },
    async renderExpandChildData(row) {
      const { spId, appName, packageName, applicableModel } = row
      try {
        row.loading = true
        const res = await this.$api.appManagement.getAppsByPackageNameNew({
          spId,
          appName,
          packageName,
          applicableModel
        })
        row.expandData = (res.result || []).map(item => {
          item.appSize = this.$utils.bytesToSize(item.appSize)

          return item
        })
      } catch (err) {
        console.log(err)
      } finally {
        row.loading = false
      }
    },

    async toggleRowExpansion(row, index) {
      const _this = this
      if (!row.showChildList) {
        this.tableData.forEach(_ => {
          _.showChildList = false
        })
      }
      row.showChildList = !row.showChildList

      if (!row.showChildList) {
        this.expands = []
      } else {
        this.expands = [row.id]
        this.renderExpandChildData(row)
      }
    },

    detailHandler(row) {
      this.currentItem = row
      this.detailVisible = true
    },
    async downLoad(row) {
      try {
        this.loading = true
        let formData = new FormData()
        formData.append('appId', row.appId)

        const res = await this.$api.appManagement.getAppUrl(formData)

        window.open(res.result, '_self')
      } catch (err) {
        console.log(err)
      } finally {
        this.loading = false
      }
    },
    async delHandler(row, data, index) {
      try {
        await this.$confirm(this.$t('appList.deleteApp'), this.$t('tips'), {
          confirmButtonText: this.$t('confirm'),
          cancelButtonText: this.$t('cancel')
        })

        await this.$api.appManagement.appDeleteVersion({ appId: row.appId, spId: row.spId })
        this.$message.success(this.$t('deleteSuccess'))
        data.splice(index, 1)
      } catch (err) {
        console.log(err)
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style scoped lang="scss">
img {
  width: 50px;
  height: 50px;
}
.expandWrap {
  ::v-deep .el-table th,
  .el-table tr {
    background-color: #f5f7fa;
  }
  .el-table__body-wrapper {
    overflow: auto;
  }
}
</style>
