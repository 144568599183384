<template>
  <el-dialog
    :close-on-click-modal="false"
    v-loading="loading"
    v-elDragDialog
    :title="`${$t('publishApp.selectTo')}${operate}${$t('publishApp.appOf')}`"
    :visible.sync="show"
  >
    <div class="header">
      <div class="left">
        <el-input
          v-model.trim="params.appName"
          @keyup.enter.native="replaceData"
          :placeholder="$t('appList.enterAppName')"
        />
        <el-button type="primary" plain @click="replaceData">{{ $t('search') }}</el-button>
      </div>
    </div>

    <div class="content" v-scroll="loadMore">
      <div class="row" v-for="item in list" :key="item.id">
        <div class="info">
          <img :src="item.iconPathUrl" />
          <div class="name">{{ item.appName }}</div>
          <div class="info-b">
            <span>{{ item.appSize }}</span>
            <span v-if="operate == $t('publishApp.release')"
              >{{ $t('deviceList.versionNo') }} V{{ item.versionName }}</span
            >
          </div>
          <div>
            <span>{{ $t('appList.applicableModel') }} {{ item.applicableModelStr || '-' }}</span>
          </div>
        </div>
        <div class="versions">
          <el-checkbox-group
            @change="
              val => {
                changeHandler(val, item)
              }
            "
            :disabled="currentSelectId !== item.id && currentSelectId !== ''"
            :class="{ hideLabel: operate == $t('publishApp.uninstall') }"
            v-model="item.checks"
          >
            <el-checkbox
              :disabled="!!(item.checks.length && !item.checks.includes(subItem.id))"
              v-for="subItem in item.children"
              :key="subItem.id"
              :label="subItem.id"
            >
              <div v-if="operate == $t('publishApp.release')" class="version">
                <span>V{{ subItem.versionName }}</span>
                <span>{{ subItem.appSize }}</span>
              </div>
            </el-checkbox>
          </el-checkbox-group>
        </div>
      </div>
    </div>

    <span slot="footer" class="dialog-footer">
      <el-button @click="show = false">{{ $t('cancel') }}</el-button>
      <el-button type="primary" @click="submit">{{ $t('confirm') }}</el-button>
    </span>
  </el-dialog>
</template>

<script>
import dialogCommonParams from '@/mixins/dialogCommonParams'
import watchParamsGetData from '@/mixins/watchParamsGetData'
export default {
  mixins: [dialogCommonParams, watchParamsGetData],
  components: {},
  props: {
    selectApp: {
      type: Array,
      default: () => []
    },
    operate: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      currentSelectId: '',
      loading: false,
      params: {
        pageSize: 50,
        currentPage: 1,
        appName: ''
      },
      targetObj: {},
      list: []
    }
  },
  async created() {
    await this.getModelList()
    await this.getData()
    if (this.selectApp.length) {
      this.init()
    }
  },
  methods: {
    changeHandler(val, item) {
      if (val.length) {
        this.currentSelectId = item.id
      } else {
        this.currentSelectId = ''
      }
    },
    async getModelList() {
      try {
        const res = await this.$api.deviceManagement.modelList({ pageSize: -1 })
        if (res) {
          const targetObj = {}

          if (res.result && res.result.list) {
            res.result.list.forEach(item => {
              targetObj[item.id] = item.name
            })
          }

          this.targetObj = targetObj
        }
      } catch (e) {
        console.log(e)
      }
    },
    loadMore() {
      ++this.params.currentPage
      this.getData()
    },
    init() {
      this.list.forEach(item => {
        const arr = this.selectApp.filter(e => e.id === item.id)
        item.checks = arr.map(item => item.id)
      })
    },
    async getData() {
      try {
        this.loading = true
        const res = await this.$api.appManagement.appList(this.params)

        if (res && res.result) {
          const list = ((res.result && res.result.list) || []).map(item => {
            if (item.applicableModel) {
              item.applicableModelStr = item.applicableModel
                .split(',')
                .map(item => this.targetObj[+item])
                .join('、')
            }

            return item
          })
          const promises = (res.result.list || []).map(item => {
            const { packageName, spId, appName, applicableModel } = item
            return this.$api.appManagement.getAppsByPackageNameNew({
              packageName,
              spId,
              appName,
              applicableModel
            })
          })
          const ress = await Promise.all(promises)
          ress.forEach((item, index) => {
            const versionList = []

            list[index].checks = []
            list[index].appSize = this.$utils.bytesToSize(list[index].appSize)

            list[index].children = (item.result || []).reduce((arr, item, index) => {
              if (!versionList.includes(item.versionName)) {
                versionList.push(item.versionName)
                item.appSize = this.$utils.bytesToSize(item.appSize)
                arr.push(item)
              }

              return arr
            }, [])

            if (this.operate === this.$t('publishApp.uninstall')) {
              list[index].children.length = 1
            }
          })

          this.list = this.params.currentPage === 1 ? list : this.list.concat(list)
        }
      } catch (err) {
        console.log(err)
      } finally {
        this.loading = false
      }
    },
    submit() {
      const data = this.list.map(item => {
        return item.checks.map(id => {
          const s = item.children.find(item => item.id === id)
          s.iconPathUrl = item.iconPathUrl
          s.applicableModel = item.applicableModel
          s.applicableModelStr = item.applicableModelStr
          return s
        })
      })

      this.$emit('selectApp', data.flat())
      this.show = false
    }
  }
}
</script>

<style lang="scss" scoped>
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .left {
    display: flex;
    button {
      margin-left: 10px;
    }
  }
}
.content {
  margin-top: 10px;
  height: 500px;
  overflow: auto;
  padding: 10px;
  border-radius: 4px;
  border: 1px solid #ccc;
  & > .row {
    & + .row {
      margin-top: 10px;
    }
  }
  .row {
    display: flex;
    img {
      width: 50px;
      height: 50px;
    }
  }
  .versions {
    display: flex;
    flex-wrap: wrap;
    .version {
      display: flex;
      flex-direction: column;
    }
    ::v-deep .el-checkbox {
      margin-bottom: 10px;
    }
    .hideLabel {
      ::v-deep .el-checkbox__label {
        display: none;
      }
    }
  }
}
.info {
  line-height: normal;
  width: 200px;
  flex-wrap: wrap;
  word-break: break-word;
  border: 1px solid #ccc;
  padding: 10px;
  margin-bottom: 5px;
  flex-shrink: 0;
  border-radius: 4px;
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-right: 10px;
  .name {
    font-size: 15px;
    font-weight: bold;
    margin: 10px 0;
    text-align: center;
  }
  .info-b {
    text-align: center;
    padding: 0 10px;
    span {
      font-size: 13px;
    }
    span + span {
      margin-left: 10px;
    }
  }
}
</style>
