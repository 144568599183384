<template>
  <page v-loading="loading" :borderBottom="true" class="page-container">
    <div slot="headerLeft">
      <span class="spanItem active">{{ $t('appList.appDetails') }}</span>
    </div>
    <div slot="headerRight" class="flex" style="align-items: center;">
      <el-button size="small" type="primary" round @click="$router.go(-1)">{{
        $t('backList')
      }}</el-button>
    </div>

    <div class="mainWrap">
      <div class="row">
        <span class="label">{{ operateName }}{{ $t('publishApp.appOf') }}:</span>
        <div class="content">
          <span v-if="currentItem.appInfos"
            >{{ currentItem.appInfos.length }}{{ $t('publishApp.ge') }}</span
          >
          <div class="row viewer" v-viewer>
            <div class="info" v-for="item in currentItem.appInfos" :key="item.appId">
              <img :src="item.iconPathUrl" />
              <div class="name" :title="item.appName">{{ item.appName }}</div>
              <div class="info-b">
                <span>{{ $utils.bytesToSize(item.appSize) }}</span>
                <span>{{ $t('deviceList.versionNo') }} {{ item.versionName }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <span class="label">{{ operateName }}{{ $t('operateList.mode') }}:</span>
        <div class="content">
          <span>{{ item.publishWayStr }}</span>
          <div class="row" v-if="item.publishWay === 5">
            <span v-for="(item, index) in list" :key="index">[ {{ item }} ]</span>
          </div>
        </div>
      </div>
      <div class="row">
        <span class="label">{{ $t('operateList.associatedDevices') }}:</span>
        <span>{{ currentItem.sumDevice }}{{ $t('adminHomePage.tower') }}</span>
        <el-link @click="downloadList" type="primary">{{
          $t('operateList.downloadDeviceList')
        }}</el-link>
      </div>
    </div>

    <div class="bottomWrap">
      <div class="switchHead">
        <el-radio-group v-model="currentView">
          <el-radio-button label="DeviceView">{{ $t('operateList.viewByDevice') }}</el-radio-button>
          <el-radio-button label="AppView">{{ $t('operateList.viewByApp') }}</el-radio-button>
        </el-radio-group>
      </div>

      <component :faterItem="currentItem" :operateType="operateType" :is="currentView" />
    </div>
  </page>
</template>

<script>
import DeviceView from './components/DeviceView'
import AppView from './components/AppView'
export default {
  components: {
    DeviceView,
    AppView
  },
  data() {
    return {
      currentView: 'DeviceView',
      loading: false,
      types: [
        {
          value: 5,
          label: this.$t('publishApp.byCustomer')
        },
        {
          value: 2,
          label: this.$t('publishApp.publishBySN')
        },
        {
          value: 3,
          label: this.$t('publishApp.publishDevice')
        },
        {
          value: 6,
          label: this.$t('publishApp.fullRelease')
        }
      ],
      item: {},
      list: []
    }
  },
  created() {
    if (this.currentItem) {
      this.getServiceList()
      const item = { ...this.currentItem }
      const e = this.types.find(e => e.value === item.publishWay)
      item.publishWayStr =
        this.operateType === 2
          ? e.label.replace(this.$t('publishApp.release'), this.$t('publishApp.uninstall'))
          : e.label
      this.item = item
    }
  },
  computed: {
    currentItem() {
      return (
        sessionStorage.getItem('currentAppDetail') &&
        JSON.parse(sessionStorage.getItem('currentAppDetail'))
      )
    },
    operateName() {
      return this.operateType === 2
        ? this.$t('publishApp.uninstall')
        : this.$t('publishApp.release')
    },
    operateType() {
      return +this.$route.params.type
    }
  },
  methods: {
    async getServiceList() {
      const res = await this.$api.systemManagement.spList({ pageSize: -1 })

      if (res && res.result.list) {
        const spNameMap = new Map()

        res.result.list.forEach(item => spNameMap.set(item.spId, item.name))

        const publishObjectIds = (this.currentItem.publishObjectIds || '').split(',')
        console.log(publishObjectIds)
        this.list = publishObjectIds.map(id => spNameMap.get(+id))
      }
    },
    async downloadList() {
      const loading = this.$loading()
      try {
        const res = await this.$api.appManagement.downloadList({
          publishId: this.currentItem.publishId,
          spId: this.currentItem.spId
        })

        this.$utils.downFile(res)
      } catch (e) {
        console.log(e)
      } finally {
        setTimeout(() => {
          loading.close()
        }, 1000)
      }
    },
    async getGrouplName(publishObjectIds) {
      try {
        this.loading = true
        const res = await this.$api.deviceManagement.getGrouplName(publishObjectIds)
        this.list = res.result.map(item => {
          return {
            label: item.name,
            value: item.id
          }
        })
      } catch (err) {
      } finally {
        this.loading = false
      }
    },
    async getDeviceName(publishObjectIds) {
      try {
        this.loading = true
        const res = await this.$api.deviceManagement.getDeviceName({ snList: publishObjectIds })
        this.list = res.result.map(item => {
          return {
            label: item.sn,
            value: item.id
          }
        })
      } catch (err) {
      } finally {
        this.loading = false
      }
    },
    async getModelName(publishObjectIds) {
      try {
        this.loading = true
        const res = await this.$api.deviceManagement.getModelName(publishObjectIds)
        this.list = res.result.map(item => {
          return {
            label: item.name,
            value: item.id
          }
        })
      } catch (err) {
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style scoped lang="scss">
.page-container {
  overflow: auto;
}
.el-button {
  padding: 6px 15px;
}

.mainWrap {
  .row {
    display: flex;

    img {
      width: 50px;
      height: 50px;
    }

    & + .row {
      margin-top: 18px;
    }
    .label {
      width: 200px;
      flex-shrink: 0;
    }
    .row {
      flex-wrap: wrap;
      margin-top: 10px;
      span + span {
        margin-left: 2px;
      }
    }
    .info {
      width: 200px;
      flex-wrap: wrap;
      word-break: break-word;
      border: 1px solid #ccc;
      padding: 10px 0;
      margin-right: 5px;
      margin-bottom: 5px;
      display: flex;
      align-items: center;
      flex-direction: column;
      .name {
        font-size: 15px;
        line-height: 34px;
        height: 34px;
        font-weight: bold;
        text-align: center;
        max-width: 180px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      .info-b {
        display: flex;
        text-align: center;
        span {
          font-size: 13px;
        }
        span + span {
          margin-left: 10px;
        }
      }
    }
  }
  ::v-deep .el-link {
    margin-left: 5px;
  }
}

.bottomWrap {
  display: flex;
  flex-direction: column;
  flex: 1;
  .switchHead {
    display: flex;
    justify-content: center;
  }
}
</style>
